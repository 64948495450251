import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useGmaoStore = defineStore('gmao', {
    state: () => {
        return {
            user: useStorage('user', {}),
            workspace: useStorage('workspace', {}),
            time: useStorage('time', []),
            timeRegister: useStorage('timeRegister', { id: null }),
            nonEffectiveType: useStorage('nonEffectiveType', ''),
            deviceId: useStorage('deviceId', ''),
            filters: useStorage('filters', {}),
            mode: useStorage('mode', null),
            v: '2.1.4',
            scanActive: false,
            offlineQueue: useStorage('offlineQueue', []),
            isMobile: useStorage('isMobile', false),
        }
    },

    actions: {},

    getters: {
        comportamientos(state) {
            return state.user.comportamientos;
        },

        modulos(state) {
            return state.user.modulos;
        },

        esTecnico(state) {
            return state.user.id_role === 5;
        },
    }
})
